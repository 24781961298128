import { MainLayout, PageHeader } from '../../components/main.layout';
import { registeredSurveysTecnolite, registeredSurveysViva, registeredSurveysCx } from '../../surveys';

let registeredSurveys: any[] = [];
switch (process.env.REACT_APP_DEMO_SURVEYS) {
  case "viva":
    registeredSurveys = registeredSurveysViva;
    break;
  case "tecnolite":
    registeredSurveys = registeredSurveysTecnolite;
    break;
  case "cxmate":
    registeredSurveys = registeredSurveysCx;
    break;
}

export function SurveyIndex () {
  return (
    <>
      <PageHeader />
      <MainLayout>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <h2 style={{ color: 'var(--color-primary)'}}>Listado de encuestas</h2>
          <p style={{ textAlign: 'center' }}><small>Solo para fines demostrativos, esta sección no es visible durante el levantamiento.</small></p>
          {
            registeredSurveys.map(s => {
              let params = ''
              if (s.params?.length) {
                s.params.forEach((p: any, i: number) => {
                  const separator = i === 0 ? '?' : '&';
                  params += `${separator}${p.key}=${encodeURI(p.value)}`
                })
              }
              return (
                <a
                  key={s.id}
                  href={`/test/${s.id}${params}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ fontSize: '16px', marginBottom: '1em', textAlign: 'center' }}
                >
                  {s.label}
                </a>
              )
            })
          }
        </div>
      </MainLayout>
    </>
  )
}